<template>
  <div class="usuario-details">
    <h3 class="header mb-2">
      <v-icon size="18" style="padding-bottom: 7px">mdi-account</v-icon>
      Usuário
    </h3>
    <v-row>
      <v-col cols="4">
        <label>E-mail</label>
        <p class="grey--text text--darken-4">{{data.email}}</p>
      </v-col>
      <v-col cols="4">
        <label>Celular</label>
        <p class="grey--text text--darken-4">{{data.celular}}</p>
      </v-col>
      <v-col cols="4">
        <label>Ativo</label>
        <p class="grey--text text--darken-4">{{data.ativo ? 'sim' : 'não'}}</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object
    }
  }
}
</script>

<style lang="scss">

</style>
