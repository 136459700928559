<template>
  <div class="lead-details">
    <h3 class="header mb-2">
      <v-icon size="18" style="padding-bottom: 7px">mdi-account-multiple</v-icon>
      Lead
    </h3>
    <v-row>
      <v-col cols="4">
        <label>Nome</label>
        <p class="grey--text text--darken-4 text-capitalize">{{data.nome}}</p>
      </v-col>
      <v-col cols="4">
        <label>CPF</label>
        <p class="grey--text text--darken-4">{{data.cpf}}</p>
      </v-col>
      <v-col v-if="data.cnpj" cols="4">
        <label>CNPJ</label>
        <p class="grey--text text--darken-4">{{data.cnpj}}</p>
      </v-col>
      <v-col :cols="data.dataNasc ? 4 : 8">
        <label>Data Nasc.</label>
        <p v-if="!data.dataNasc" class="caption"><i>não informado</i></p>
        <p v-else class="grey--text text--darken-4">{{data.dataNasc}}</p>
      </v-col>
      <v-col v-if="data.dataNasc" cols="4">
        <label>Idade</label>
        <p class="grey--text text--darken-4">{{ data.dataNasc ? new Date().getFullYear() - data.dataNasc.split("/").reverse()[0] : '' }}</p>
      </v-col>
      <v-col cols="4">
        <label>Cidade</label>
        <p class="grey--text text--darken-4">{{data.cidade}}</p>
      </v-col>
      <v-col cols="4">
        <label>UF</label>
        <p class="grey--text text--darken-4">{{data.uf}}</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object
    }
  }
}
</script>

<style lang="scss">

</style>
