<template>
  <div class="leads-list">
    <v-card-title class="text-h4">
      <v-layout justify-space-between>
        {{ title }}
        <div>
          <v-row>
            <v-col>
              <v-btn icon @click="onPrevPage">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </v-col>
            <v-col>
              <v-btn icon @click="onNextPage">
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-layout>
    </v-card-title>
    <v-card-text>
      <v-data-table
          :headers="headers"
          :header-props="headerProps"
          :items="$store.getters['database/lead/getData']"
          :search="tableSearch"
          :loading="lead.status === 1"
          fixed-header
          item-key=""
          disable-pagination
          hide-default-footer
          class="scroll-horizontal"
      >
        <template #item.usuario.email="{item}">
          {{ item.usuario.email }}
        </template>
        <template #item.solicitacoes="{item}">
          {{ item.solicitacoes.length }}
        </template>
        <template #item.updatedAt="{item}">
          {{ new Date(item.updatedAt).toLocaleString() }}
        </template>
        <template #item.createdAt="{item}">
          {{ new Date(item.createdAt).toLocaleString() }}
        </template>
        <template #item.acoes="{item}">
          <v-layout>
            <details-modal :data="mergeData({lead: item}, {solicStatus: lead.data.solicitacaoStatus})">
              <template #default="{on, attrs}">
                <v-btn icon color="blue lighten-2" class="mr-4" v-on="on" v-bind="attrs">
                  <v-icon title="detalhes">mdi-card-text</v-icon>
                </v-btn>
              </template>
            </details-modal>
<!--            <form-modal :data="item">-->
<!--              <template #default="{on, attrs}">-->
<!--                <v-btn icon color="primary" class="mr-2" v-on="on" v-bind="attrs">-->
<!--                  <v-icon title="editar">mdi-pencil</v-icon>-->
<!--                </v-btn>-->
<!--              </template>-->
<!--            </form-modal>-->
<!--            <delete-modal>-->
<!--              <template #default="{on, attrs}">-->
<!--                <v-btn icon class="ml-1" color="error" v-on="on" v-bind="attrs">-->
<!--                  <v-icon title="excluir">mdi-delete</v-icon>-->
<!--                </v-btn>-->
<!--              </template>-->
<!--            </delete-modal>-->
          </v-layout>
        </template>
      </v-data-table>
    </v-card-text>
  </div>
</template>

<script>
// import DeleteModal from "@/components/modals/delete-modal";
// import FormModal from "@/views/database/leads/form";
import DetailsModal from "@/views/database/leads/details";
export default {
  components: {
    // FormModal,
    // DeleteModal,
    DetailsModal
  },
  data: () => ({
    title: "Leads",
    tableSearch: "",
    headerProps: {
      sortByText: "Ordenar por",
    },
    headers: [
      { text: "Cadastro", value: "createdAt" },
      { text: "Atualização", value: "updatedAt" },
      { text: "ID", value: "id" },
      { text: "Usuário", value: "usuario.email" },
      { text: "CPF", value: "cpf" },
      { text: "CNPJ", value: "cnpj" },
      { text: "Data Nasc.", value: "dataNasc" },
      { text: "Idade", value: "idade" },
      { text: "Cidade", value: "cidade" },
      { text: "UF", value: "uf" },
      { text: "Solicitações", value: "solicitacoes" },
      { text: "Ações", value: 'acoes' },
    ],
  }),
  computed: {
    lead() {
      return this.$store.state.database.lead;
    }
  },
  mounted() {
    this.$store.dispatch('database/lead/getAll', {page: this.$route.query.page ? this.$route.query.page : '1'})
  },
  methods: {
    mergeData(dataA, dataB) {
      return {...dataA, ...dataB}
    },
    onNextPage() {
      location.href = location.pathname + `?page=${this.$route.query.page > 0 ? parseInt(this.$route.query.page) + 1 : 2}`
    },
    onPrevPage() {
      location.href = location.pathname + `?page=${this.$route.query.page > 1 ? parseInt(this.$route.query.page) - 1 : 1}`
    }
  }
}
</script>

<style lang="scss">
.leads-list {
  .scroll-horizontal {
    .v-data-table__wrapper {
      width: 120vw!important;
    }
  }
}

</style>
