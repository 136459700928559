<template>
  <div class="solicitacoes-tables">
    <h3 class="header mb-2">
      <v-icon size="18" style="padding-bottom: 7px">mdi-swap-horizontal-bold</v-icon>
      Solicitações
    </h3>
    <v-data-table
        :headers="headers"
        :header-props="headerProps"
        :items="data.solicitacoes"
        :search="tableSearch"
        :loading="status === 1"
        fixed-header
        item-key=""
        disable-pagination
        hide-default-footer
    >
      <template #item.createdAt="{item}">
        {{ new Date(item.createdAt).toLocaleString() }}
      </template>
      <template #item.tipoStatus="{item}">
        {{ getTipoStatus(data.status, item.id) }}
      </template>
      <template #item.status="{item}">
        {{ getStatus(data.status, item.id) }}
      </template>
      <template #item.valorEmprestimo="{item}">
        {{ item.valorEmprestimo | currency('R$', 2, { spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.', decimalSeparator: ',' }) }}
      </template>
      <template #item.valorParcela="{item}">
        {{ item.valorParcela | currency('R$', 2, { spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.', decimalSeparator: ',' }) }}
      </template>
      <template #item.acoes="{item}">
        <solicitacoes-status-modal
            :data="{
            usuario: data.usuario,
            lead: data.lead,
            idSolicitacao: item.id
            }">
          <template #default="{on, attrs}">
            <v-btn icon color="blue lighten-2" class="mr-4" v-on="on" v-bind="attrs">
              <v-icon title="ver detalhes">mdi-card-text</v-icon>
            </v-btn>
          </template>
        </solicitacoes-status-modal>
      </template>
    </v-data-table>
  </div>
</template>

<script>

import SolicitacoesStatusModal from "@/components/modals/solicitacoes-status-modal";
export default {
  components: {SolicitacoesStatusModal},
  props: {
    data: {
      type: Object
    },
    status: {
      type: Number
    }
  },
  data: () => ({
    tableSearch: "",
    headerProps: {
      sortByText: "Ordenar por",
    },
    headers: [
      { text: "ID", value: "id" },
      { text: "Data/Hora", value: "createdAt" },
      { text: "Empréstimo", value: "valorEmprestimo" },
      { text: "Parcela", value: "valorParcela" },
      { text: "Status", value: "tipoStatus" },
      { text: "Detalhe Status", value: "status" },
      { text: "Ações", value: 'acoes' },
    ],
  }),
  methods: {
    getStatus(data, id) {
      const result = data ? data.find(e => e.idSolicitacao === id) : {status: 'erro ao obter dados'}
      return result ? result.status : 'erro ao obter status';
    },
    getTipoStatus(data, id) {
      const result = data ? data.find(e => e.idSolicitacao === id) : {tipoStatus: 'erro ao obter dados'}
      return result ? result.tipoStatus : 'erro ao obter tipo de status'
    },
  }
}
</script>

<style scoped>

</style>
