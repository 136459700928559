<template>
  <div class="solicitacoes-status-tables">
    <h3 class="header mb-2">
      <v-icon size="18" style="padding-bottom: 7px">mdi-swap-horizontal-bold</v-icon>
      Histórico de Status da Solicitação
    </h3>
    <v-data-table
        :headers="headers"
        :header-props="headerProps"
        :items="data.solicitacoesStatus"
        :search="tableSearch"
        :loading="status === 1"
        fixed-header
        item-key=""
        disable-pagination
        hide-default-footer
    >
      <template #item.createdAt="{item}">
        {{ new Date(item.createdAt).toLocaleString() }}
      </template>
      <template #item.quiz="{item}">
        <quiz-modal v-if="item.respostaQuiz" :data="{respostaQuiz: item.respostaQuiz}">
          <template #default="{on, attrs}">
            <v-btn icon color="blue lighten-2" class="mr-4" v-on="on" v-bind="attrs">
              <v-icon title="ver Quiz">mdi-message-bulleted</v-icon>
            </v-btn>
          </template>
        </quiz-modal>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import QuizModal from "@/components/modals/quiz-modal";
export default {
  components: {QuizModal},
  props: {
    data: {
      type: Object
    },
    status: {
      type: Number
    }
  },
  data: () => ({
    tableSearch: "",
    headerProps: {
      sortByText: "Ordenar por",
    },
    headers: [
      { text: "ID", value: "id" },
      { text: "Data/Hora", value: "createdAt" },
      { text: "Status", value: "tipoStatus.nome" },
      { text: "Descrição", value: "tipoStatus.descricao" },
      { text: "Detalhes", value: "quiz" },
    ],
  }),
  methods: {
    getStatus(data, id) {
      const result = data ? data.find(e => e.idSolicitacao === id) : {status: 'erro ao obter dados'}
      return result.status;
    },
    // hasQuiz(item) {
    //   return item.respostaQuiz && item.respostaQuiz.id;
    // }
  }
}
</script>

<style scoped>

</style>
