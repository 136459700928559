<template>
  <div class="solicitacoes-details">
    <v-dialog v-model="show" persistent scrollable>
      <v-card>
        <v-card-title class="headline mb-4">
          {{ title }}
          <v-layout justify-end>
            <v-btn color="grey" text small class="mr-4" @click="onClickClose">
              <v-icon class="mr-2">mdi-close</v-icon>
              fechar
            </v-btn>
          </v-layout>
        </v-card-title>
        <v-card-text>
          <quiz-details no-title :data="data.respostaQuiz" />
          <v-divider class="my-4" />
        </v-card-text>
      </v-card>
      <template #activator="{on, attrs}">
        <slot v-bind="{on, attrs}" />
      </template>
    </v-dialog>
  </div>
</template>

<script>

import QuizDetails from "@/components/details/quiz-details";
export default {
  components: {QuizDetails},
  props: {
    data: {
      type: Object
    },
  },
  data: () => ({
    title: 'Quiz',
    show: false
  }),
  computed: {
    // user() {
    //   return this.$store.state.database.user;
    // }
  },
  watch: {
    // show(nextState) {
    //   if(nextState) {
    //     this.$store.dispatch('database/answerQuiz/getOne', {id: this.data.respostaQuiz.id})
    //   }
    // }
  },
  methods: {
    onClickClose() {
      this.show = false;
    },
  },
}
</script>

<style lang="scss">

</style>
