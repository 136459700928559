<template>
  <div class="quiz-details">
    <h3 v-if="!noTitle" class="header mb-2">
      <v-icon size="18" style="padding-bottom: 7px" color="blue lighten-2">mdi-message-bulleted</v-icon>
      Quiz - {{ data.quiz.titulo }}
    </h3>
    <v-row>
      <v-col v-for="(item, i) in answersSorted" cols="4" :key="i">
        <label class="title">
          <v-icon size="16" color="blue lighten-2">mdi-message-text</v-icon>
          {{ item.pergunta.descricao  }}
        </label>
        <p class="grey--text text--darken-2">
          R. :
          <span class="grey--text text--darken-3 font-weight-bold">
            {{ getAnswer(item.pergunta.opcoes, item) }}
          </span>
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object
    },
    noTitle: {
      type: Boolean
    }
  },
  computed: {
    answersSorted() {
      return this.data.respostasPergunta.slice().sort((a, b) => a.pergunta.quizPerguntas[0].ordem - b.pergunta.quizPerguntas[0].ordem)
    }
  },
  methods: {
    getAnswer(opcs, item) {
      if(item.tipo === 'alternativa') {
        const opc = opcs.find(e => String(e.id) === String(item.descricao))
        return opc.descricao
      } else {
        if(item.tipoDado === 'monetario') {
          return this.$options.filters.currency(item.descricao, 'R$', 2, { spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.', decimalSeparator: ',' })
        } else {
          return item.descricao
        }
      }
    }
  },
}
</script>

<style lang="scss">

</style>
