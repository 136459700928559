<template>
  <div class="leads-details">
    <v-dialog v-model="show" persistent fullscreen scrollable>
      <v-card>
        <v-card-title class="headline mb-4">
          {{ title }}
          <v-layout justify-end>
            <v-btn color="grey" text small class="mr-4" @click="onClickClose">
              <v-icon class="mr-2">mdi-close</v-icon>
              fechar
            </v-btn>
          </v-layout>
        </v-card-title>
        <v-card-text>
          <usuario-details :data="data.lead.usuario" />
          <v-divider class="my-4" />
          <lead-details :data="data.lead" />
          <v-divider class="my-4" />
          <solicitacoes-table :data="{
            solicitacoes: data.lead.solicitacoes,
            status: data.solicStatus,
            lead: data.lead,
            usuario: data.lead.usuario}"
          />
          <v-divider class="my-4" />
        </v-card-text>
      </v-card>
      <template #activator="{on, attrs}">
        <slot v-bind="{on, attrs}" />
      </template>
    </v-dialog>
  </div>
</template>

<script>
import UsuarioDetails from "@/components/details/usuario-details";
import LeadDetails from "@/components/details/lead-details";
import SolicitacoesTable from "@/components/tables/solicitacoes-table";
export default {
  components: {SolicitacoesTable, LeadDetails, UsuarioDetails},
  props: {
    data: {
      type: Object
    },
    onSave: {
      type: Function,
      default: () => {}
    },
    onCancel: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    title: 'Detalhes',
    show: false,
  }),
  methods: {
    onClickClose() {
      this.show = false;
    }
  }
}
</script>

<style lang="scss">

</style>
