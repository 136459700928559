<template>
  <div class="solicitacoes-details">
    <v-dialog v-model="show" persistent fullscreen scrollable>
      <v-card>
        <v-card-title class="headline mb-4">
          {{ title }}
          <v-layout justify-end>
            <v-btn color="grey" text small class="mr-4" @click="onClickClose">
              <v-icon class="mr-2">mdi-close</v-icon>
              fechar
            </v-btn>
          </v-layout>
        </v-card-title>
        <v-card-text>
          <usuario-details :data="data.usuario" />
          <v-divider class="my-4" />
          <lead-details :data="data.lead" />
          <v-divider class="my-4" />
          <solicitacoes-status-table :data="{solicitacoesStatus: requestStatus.data}" />
          <v-divider class="my-4" />
        </v-card-text>
      </v-card>
      <template #activator="{on, attrs}">
        <slot v-bind="{on, attrs}" />
      </template>
    </v-dialog>
  </div>
</template>

<script>
import UsuarioDetails from "@/components/details/usuario-details";
import LeadDetails from "@/components/details/lead-details";
import SolicitacoesStatusTable from "@/components/tables/solicitacoes-status-table";
export default {
  components: {SolicitacoesStatusTable, LeadDetails, UsuarioDetails},
  props: {
    data: {
      type: Object
    },
  },
  data: () => ({
    title: 'Detalhes da Solicitação',
    show: false
  }),
  computed: {
    requestStatus() {
      return this.$store.state.database.requestStatus;
    }
  },
  watch: {
    show(nextState) {
      if(nextState) {
        this.$store.dispatch('database/requestStatus/getAllByRequest', {id: this.data.idSolicitacao})
      }
    }
  },
  methods: {
    onClickClose() {
      this.show = false;
    },
  },
}
</script>

<style lang="scss">

</style>
